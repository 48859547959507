import React from "react";

const Footer = () => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-once
      class="w-screen flex flex-col p-4 pt-10 pb-10 bg-darkgreen text-gold"
    >
      <h1 class="pb-4 font-title text-3xl lg:text-7xl">
        Sala Weselna Hacjenda w jasionówce
      </h1>
      <h1 class="font-regular">
        Sala bankietowa Hacjenda w Jasionówce organizuje niezapomniane przyjęcia
        okolicznościowe. Jeśli interesuje Cię organizacja bankietów i imprez to
        sprawdź naszą ofertę.
      </h1>
    </div>
  );
};

export default Footer;
